<template>
  <main id="ins">
    <section id="page-title">
      <row>
        <column :lg="8" class="page-title">
          <div align="left" class="back">
            <router-link to="/">
              <img src="../../../src/assets/BackArrow.svg" alt="back-arrow"/> Back
            </router-link>
          </div>
          <h1 class="title">INS Lessons</h1>
        </column>
        <column :lg="4" class="progress-summary">
          <div class="doughnut-1"><aim-doughnut-chart :doughnutChartData="doughnutChartData1"></aim-doughnut-chart></div>
          <div class="doughnut-2"><time-doughnut-chart :doughnutChartData="doughnutChartData2"></time-doughnut-chart></div>
        </column>
      </row>
    </section>
    <section class="ins-select-area">
      <row :gutter="12" >
        <column :lg="1.5"><h3 class="ins-select-country">Select Country</h3></column>
        <column :lg="2.5" class="ins-select-box"><v-select :options="countries" v-model="selectedCountry" placeholder="Show all" :searchable="false"></v-select></column>
        <column :lg="1.5"><h3 class="ins-select-camp">Select Camp</h3></column>
        <column :lg="2.5" class="ins-select-box">
          <v-select :options="camps" v-model="selectedCamp" class="select-camp" placeholder="Select country to activate" :searchable="false" :disabled="campSelectboxDisabled">
            <span slot="no-options">
              <h3>No more available options</h3>
            </span>
          </v-select>
        </column>
        <column :lg="1.5"><h3 class="ins-select-school">Select School</h3></column>
        <column :lg="2.5" class="ins-select-box">
          <v-select :options="schools" v-model="selectedSchool" class="select-school" placeholder="Select camp to activate" :searchable="false" :disabled="schoolSelectboxDisabled">
            <span slot="no-options">
              <h3 style="text-align:left; padding-left: 1.8rem; color:#686868; font-family: Helvetica; font-size:1.4rem;">No more available options</h3> 
            </span>            
          </v-select>
        </column>
      </row>
    </section>
    <section :gutter="12" class="chart-title-area">
      <row class="chart-title">
        <column :lg="8" :xs="6"><h2 class="ins-sub-title">Number of lessons using INS {{country}} {{camp}} {{school}}</h2></column>
        <column class="chart-summary" :lg="4" :xs="6">
          <div class="total-lessons"> <span><h1>{{ totalLessons }}</h1> <h2>lessons</h2></span> <h3 style="font-family:'Source Sans Pro';">using INS</h3></div>
          <div class="growth-rate"> <h1>{{growthRate}}</h1> <h3 style="font-family:'Source Sans Pro';">in {{selectedYear}}</h3></div>
        </column>
      </row>
    </section>
    <section id="chart-area">
      <row :gutter="12" class="chart-main">
        <column :lg="8.16" class="line-chart-area">
          <h3> No of lessons in {{selectedYear}}</h3>
          <line-chart :chart-data="chartData" :options="options" v-if="linechartShow === true"></line-chart>
          <stacked-bar-chart id="stacked-bar-chart" :chart-data="stackedBarChartData" :options="stackedBarchartOption" v-if="stackedChartShow === true"></stacked-bar-chart>
          <column :lg="4" :xs="12" class="year-select-box" ><v-select :options="yearOptions" v-model="selectedYear" class="select-year" placeholder="Show all" :searchable="false"></v-select></column>
        </column>
        <column :lg="3.84" class="summary-area">
          <div class="country-wrapper" v-for="country in summaryBoxData" v-bind:key="country.vForId" :value="country.vForId">
            <div class="text-container">
              <input type="checkbox" v-bind:class="country.cssId" v-bind:id="country.cssId" v-bind:key="country.vForId" :value="country.vForId" v-model="checkedItems" style="display:none">
                <label v-bind:class="country.cssId" v-bind:for="country.cssId">
                  <div v-bind:class="country.cssId" v-bind:for="country.cssId" style="justify-content:center; align-item:center;">
                    <span v-bind:class="country.cssId" v-bind:for="country.cssId" style="color:#ffffff; margin:2px 2px 2px 5px; width:10px; height:10px;">V</span>
                  </div>
                </label>
                <!-- Lessons summary start-->
                <div class="summary-text" v-bind:class="country.cssId" v-bind:for="country.cssId" v-if="linechartShow === true">
                  <div v-bind:class="country.cssId" v-bind:for="country.cssId" style="border:none; color:'#D8D8D8' !important; display:flex; width:27.7rem; justify-content:space-between; align-items:flex-end;">
                    <div>
                      <h1 style="display: inline; color:'#D8D8D8'; font-family: Helvetica; font-size:3rem; font-weight:500;" v-bind:class="country.cssId" v-bind:for="country.cssId">
                        {{ country.totalLessons }}
                      </h1>
                      <h2 style="display: inline; color:'#D8D8D8'; font-family:'Source Sans Pro'; font-size:2.2rem;" v-bind:class="country.cssId" v-bind:for="country.cssId">lessons </h2>
                    </div>
                    <div class="summary-bar-chart-container" v-if="linechartShow === true" style="align-self:flex-end;">
                      <bar-chart id="bar-chart" class="barChart" :chart-data="barChartData[country.name]" :options="barchartOption"></bar-chart>
                    </div>
                  </div>
                  <div v-bind:class="country.cssId" v-bind:for="country.cssId" style="text-align:left; border:none; color:'#D8D8D8';">
                    <h2 style="font-family:'Source Sans Pro'; font-size:1.4rem; font-weight:300;">in {{country.vForId}}</h2>
                  </div>
                </div>
                <!-- Lessons summary end-->
                <!-- Topic summary -->
                <div class="summary-text" v-bind:class="country.cssId" v-bind:for="country.cssId" v-if="stackedChartShow === true">
                  <div v-bind:class="country.cssId" v-bind:for="country.cssId" style="border:none; color:'#D8D8D8' !important; padding: 0;">
                      <h2 v-bind:class="country.cssId" v-bind:for="country.cssId">
                        {{ country.vForId }}
                      </h2>
                  </div>
                </div>
                <!-- Topic summary end -->
            </div>
          </div>
        </column>
      </row>
      <Table :tableData="tableData" v-if="linechartShow === true"></Table>
      <table-for-topic :TopicTableData="TopicTableData" v-if="stackedChartShow === true"></table-for-topic>
    </section>
  </main>
</template>

<script>
// import LineChart from '../components/Chart/LineChart.js'
import LineChart from '../../components/Chart/LineChart.js'
import BarChart from '../../components/Chart/BarChart.js'
import StackedBarChart from '../../components/Chart/StackedBarChart.js'
import AimDoughnutChart from '../../components/Chart/AimDoughnutChart.vue'
import TimeDoughnutChart from '../../components/Chart/TimeDoughnutChart.vue'
import Table from '../components/Table'
import TableForTopic from '../components/TableforTopic'
import { setYearSelectBox, getCountries, getCamps, getSchools, getLessons, getLessonsByTopics, getTotalLessonsByCountry, getTotalLessonsByCamp } from '../data/data-provider.js'
import { getAllPurpleColor, getLineChartColorScheme } from '../data/colour-scheme.js'
import { calcSum, compareDataByYear, getLineChartData, getTableData, getBarChartData, getStackedBarChartData } from '../data/data-handler'

export default {
  components: {
    LineChart,
    BarChart,
    Table,
    TableForTopic,
    StackedBarChart,
    AimDoughnutChart,
    TimeDoughnutChart
  },
  data () {
    return {
      viewMode: 'All',
      selectedCountry: null,
      selectedCamp: null,
      selectedSchool: null,
      selectedYear: 2019,
      chartData: {},
      linechartShow: true,
      stackedChartShow: false,
      campSelectboxDisabled: true,
      schoolSelectboxDisabled: true,
      barChartData: [],
      stackedBarChartData: {},
      doughnutChartData1: {
        box: 'box1',
        title: 'Aim',
        subtitle1: '800',
        subtitle2: 'using INS',
        percentage: '87',
        insideText: 'complete',
        color: '#8954BA'
      },
      doughnutChartData2: {
        box: 'box2',
        title: 'Time',
        subtitle1: '3 years',
        subtitle2: '',
        percentage: '1',
        insideText: 'more year',
        color: '#0091FF'
      },
      tableData: [],
      TopicTableData: [],
      summaryBoxData: [],
      yearOptions: [],
      countries: [],
      camps: [],
      schools: [],
      country: '',
      camp: '',
      school: '',
      checkedItems: [],
      totalLessons: '',
      growthRate: '',
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            gridLines: {
              color: '#ffffff'
            },
            ticks: {
              callback: function (value, index) {
                const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                value = months[index]
                return value
              }
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        legend: {
          labels: {
            boxWidth: 10,
            fontSize: 14,
            padding: 20,
            usePointStyle: true
          }
        },
        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']          
              return data.datasets[0].label + ' | ' + months[tooltipItem[0].index]
            },
            label: function (tooltipItem) {
              return tooltipItem.value + ' lessons'
            }
          }
        }
      },
      barchartOption: {
        animation: {
          duration: 0
        },
        scales: {
          xAxes: [{
            display: false
          }],
          yAxes: [{
            display: false
          }]
        },
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            title: function (tooltipItem) {
              const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']          
              return months[tooltipItem[0].index]
            }
          }
        }
      },
      stackedBarchartOption: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            gridLines: {
              color: '#ffffff'
            },
            ticks: {
              callback: function (value, index) {
                const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                value = months[index]
                return value
              }
            }
          }],
          yAxes: [{
            stacked: true,
            suggestedMin: true,
            ticks: {
              beginAtZero: true
            }
          }]
        },
        legend: {
          labels: {
            boxWidth: 10
          }
        },
        tooltips: {
          callbacks: {
            title: function (tooltipItem) {
              const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']          
              return months[tooltipItem[0].index]
            }
          }
        }        
      },
      doughnutChartOption: {
        animation: {
          duration: 0
        },
        scales: {
          xAxes: [{
            display: false
          }],
          yAxes: [{
            display: false
          }]
        },
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            title: function (tooltipItem) {
              const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']          
              return months[tooltipItem[0].index]
            }
          }
        }
      },      
    }
  },
  mounted () {
    this.showNavBar()
    this.countries = getCountries() // Set initial Country select box options
    this.yearOptions = setYearSelectBox() // Set initial Year select box options
    this.updateData()
  },
  methods: {
    showNavBar () {
      const navbar = document.getElementById('nav')
      navbar.style.display = 'inline'
    },
    updateData () {
      this.updateConditionalRendering()
      let lessons = {}
      let prevYearLessons = {}
      let totalCurrLessons = []
      let totalPrevLessons = []
      let tableLessons = {}
      let prevTableLessons = {}

      switch (this.viewMode) {
        case 'All':
          tableLessons = getLessons(getCountries(), [], [], this.selectedYear)
          prevTableLessons = getLessons(getCountries(), [], [], this.selectedYear - 1)
          if (this.checkedItems.length === 0) {
            lessons = getLessons([], [], [], this.selectedYear)
            prevYearLessons = getLessons([], [], [], this.selectedYear - 1)
            this.totalLessons = calcSum(Object.values(lessons.lessons[0]))
            this.growthRate = compareDataByYear(Object.values(prevYearLessons.lessons[0]), Object.values(lessons.lessons[0]))
            this.chartData = getLineChartData(lessons, getAllPurpleColor)
          } else {
            lessons = tableLessons
            this.chartData = this.filterChartData(getLineChartData(lessons, getLineChartColorScheme), this.checkedItems)
          }
          this.barChartData = getBarChartData(getTableData('Country', tableLessons, prevTableLessons))
          this.tableData = getTableData('Country', tableLessons, prevTableLessons)
          this.summaryBoxData = this.filterTopics(getTableData('Country', tableLessons, prevTableLessons))
          this.updateColors(this.viewMode, getLineChartColorScheme)
          break

        case 'Country':
          tableLessons = getLessons([this.selectedCountry], getCamps(this.selectedCountry), [], this.selectedYear)
          prevTableLessons = getLessons([this.selectedCountry], getCamps(this.selectedCountry), [], this.selectedYear - 1)
          if (this.checkedItems.length === 0) {
            lessons = getTotalLessonsByCountry(this.selectedCountry, this.selectedYear)
            prevYearLessons = getTotalLessonsByCountry(this.selectedCountry, this.selectedYear - 1)
            this.chartData = getLineChartData(lessons, getAllPurpleColor)
          } else {
            lessons = getLessons([this.selectedCountry], getCamps(this.selectedCountry), [], this.selectedYear)
            prevYearLessons = getLessons([this.selectedCountry], getCamps(this.selectedCountry), [], this.selectedYear - 1)
            this.chartData = this.filterChartData(getLineChartData(lessons, getLineChartColorScheme), this.checkedItems)
          }
          totalCurrLessons = lessons.lessons.flatMap(el => Object.values(el))
          totalPrevLessons = prevYearLessons.lessons.flatMap(el => Object.values(el))
          this.totalLessons = calcSum(totalCurrLessons)
          this.growthRate = compareDataByYear(totalPrevLessons, totalCurrLessons)
          this.barChartData = getBarChartData(getTableData('Camps', tableLessons, prevTableLessons))
          this.tableData = getTableData('Camps', tableLessons, prevTableLessons)
          this.summaryBoxData = this.filterTopics(getTableData('Camps', tableLessons, prevTableLessons))
          this.updateColors(this.viewMode, getLineChartColorScheme)
          break

        case 'Camp':
          // table lessons data
          tableLessons = getLessons([this.selectedCountry], [this.selectedCamp], getSchools(this.selectedCountry, this.selectedCamp), this.selectedYear)
          prevTableLessons = getLessons([this.selectedCountry], [this.selectedCamp], getSchools(this.selectedCountry, this.selectedCamp), this.selectedYear - 1)
          
          if (this.checkedItems.length === 0) {
            lessons = getTotalLessonsByCamp(this.selectedCountry, this.selectedCamp, this.selectedYear)
            prevYearLessons = getTotalLessonsByCamp(this.selectedCountry, this.selectedCamp, this.selectedYear - 1)
            this.chartData = getLineChartData(lessons, getAllPurpleColor)
          } else {
            lessons = getLessons([this.selectedCountry], [this.selectedCamp], getSchools(this.selectedCountry, this.selectedCamp), this.selectedYear)
            prevYearLessons = getLessons([this.selectedCountry], [this.selectedCamp], getSchools(this.selectedCountry, this.selectedCamp), this.selectedYear - 1)
            this.chartData = this.filterChartData(getLineChartData(lessons, getLineChartColorScheme), this.checkedItems)
          }
          totalCurrLessons = lessons.lessons.flatMap(el => Object.values(el))
          totalPrevLessons = prevYearLessons.lessons.flatMap(el => Object.values(el))
          this.totalLessons = calcSum(totalCurrLessons)
          this.growthRate = compareDataByYear(totalPrevLessons, totalCurrLessons)
          this.barChartData = getBarChartData(getTableData('Schools', tableLessons, prevTableLessons))
          this.tableData = getTableData('Schools', tableLessons, prevTableLessons)
          this.summaryBoxData = this.filterTopics(getTableData('Schools', tableLessons, prevTableLessons))
          this.updateColors(this.viewMode, getLineChartColorScheme)
          break

        case 'School':
          lessons = getLessonsByTopics([this.selectedCountry], [this.selectedCamp], [this.selectedSchool], this.selectedYear)
          prevYearLessons = getLessonsByTopics([this.selectedCountry], [this.selectedCamp], [this.selectedSchool], this.selectedYear - 1)
          totalCurrLessons = lessons.lessons.flatMap(el => Object.values(el))
          totalPrevLessons = prevYearLessons.lessons.flatMap(el => Object.values(el))
          this.totalLessons = calcSum(totalCurrLessons)
          this.growthRate = compareDataByYear(totalPrevLessons, totalCurrLessons)
          this.stackedBarChartData = this.filterChartData(getStackedBarChartData(lessons, getLineChartColorScheme), this.checkedItems)
          this.TopicTableData = getTableData('Topics', lessons, prevYearLessons)
          this.summaryBoxData = this.filterTopics(getTableData('Topics', lessons, prevYearLessons)) // for checkbox rendering
          this.updateColors(this.viewMode, getLineChartColorScheme)
          break
      }
    },
    updateConditionalRendering () {
      switch (this.viewMode) {
        case 'All':
          this.linechartShow = true
          this.stackedChartShow = false
          this.camplSelectboxDisabled = true
          this.schoolSelectboxDisabled = true
          this.selectedCountry = null
          this.selectedCamp = null
          this.selectedSchool = null
          this.country = '- across countries'
          this.camp = ''
          this.school = ''
          break
        case 'Country':
          this.linechartShow = true
          this.stackedChartShow = false
          this.campSelectboxDisabled = false
          this.schoolSelectboxDisabled = true
          this.selectedCamp = null
          this.selectedSchool = null
          this.camps = getCamps(this.selectedCountry)
          this.country = '- ' + this.selectedCountry
          this.camp = ''
          this.school = ''
          break
        case 'Camp':
          this.linechartShow = true
          this.stackedChartShow = false
          this.campSelectboxDisabled = false
          this.schoolSelectboxDisabled = false
          this.selectedSchool = null
          this.schools = getSchools(this.selectedCountry, this.selectedCamp)
          this.camp = ', ' + this.selectedCamp
          this.school = ''
          break
        case 'School':
          this.linechartShow = false
          this.stackedChartShow = true
          this.campSelectboxDisabled = false
          this.schoolSelectboxDisabled = false
          this.school = ', ' + this.selectedSchool
          break
      }
    },
    filterTopics (tableData) {
      const filtered = tableData.filter(el => el.totalLessons !== 0)
      return filtered
    },
    uncheckAllCheckboxes () {
      for (let i = 0; i < this.checkedItems.length; i++) {
        const checkedItem = this.checkedItems[i]
        const cssId = checkedItem.toLowerCase().replaceAll(' ', '-')
        const dom = document.getElementsByClassName(`${cssId}`)
        dom[0].checked = false
      }
      this.checkedItems = []
    },
    filterChartData (chartData, filter) {
      if (filter.length !== 0) {
        const filtered = chartData.datasets.filter(el => filter.indexOf(el.label) !== -1)
        chartData.datasets = filtered
      }
      return chartData
    },
    updateColors (view, colorScheme) {
      const tableFontDomIndex = view === 'School' ? 7 : 9
      for (let i = 0; i < this.summaryBoxData.length; i++) {
        const cssId = this.summaryBoxData[i].cssId
        const dom = document.getElementsByClassName(`${cssId}`)
        if (dom.length !== 0 && dom[0].checked === true) {
          const checkedColor = colorScheme(this.summaryBoxData[i].colorIndex)
          dom[1].style.color = checkedColor // label
          dom[2].style.border = `1px solid ${checkedColor}` // connected div to checkbox
          dom[3].style.color = checkedColor // V
          dom[4].style.color = checkedColor // Topic text
          dom[tableFontDomIndex].style.color = checkedColor // Table name
        } else if (dom.length !== 0 && !dom[0].checked) {
          dom[1].style.color = '#D8D8D8'
          dom[2].style.border = '1px solid #D8D8D8'
          dom[3].style.color = '#ffffff'
          dom[4].style.color = '#D8D8D8'
          dom[tableFontDomIndex].style.color = '#212529'
        }
      }
    }
  },
  watch: {
    checkedItems () {
      this.updateData()
    },
    selectedYear () {
      this.uncheckAllCheckboxes()
      this.updateData()
    },
    selectedCountry () {
      if (this.selectedCountry === null) {
        this.viewMode = 'All'
        this.uncheckAllCheckboxes()
        this.updateData()
      } else {
        this.viewMode = 'Country'
        this.uncheckAllCheckboxes()
        this.updateData()
      }
    },
    selectedCamp () {
      if (this.selectedCamp === null) {
        this.viewMode = 'Country'
        this.uncheckAllCheckboxes()
        this.updateData()
      } else {
        this.viewMode = 'Camp'
        this.uncheckAllCheckboxes()
        this.updateData()
      }
    },
    selectedSchool () {
      if (this.selectedSchool === null) {
        this.viewMode = 'Camp'
        this.uncheckAllCheckboxes()
        this.updateData()
      } else {
        this.viewMode = 'School'
        this.uncheckAllCheckboxes()
        this.updateData()
      }
    }
  }
}
</script>

<style src="@/assets/styles/modules/pages/Ins.css"></style>
